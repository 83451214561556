import React from 'react';

const Error404 = () => {
  return (
    <div>
      <p>
        Page not found... but you've just discovered new life form in entire
        universe
      </p>
    </div>
  );
};

export default Error404;
