import React from 'react';

const Error = () => {
  return (
    <div>
      <h3>Houston, we have a problem!</h3>
    </div>
  );
};

export default Error;
